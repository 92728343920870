const appConfig = {
  base: "http://localhost:9001",
  api: "https://mashinno.com/x-api",
  debugPath: "http://localhost:9001",
  // port: 9002,
  version: 1,
  debugMode: false,
  useLimeasterSocket: true,

  applicationPages: [
    {
      type: "exact",
      path: "/cart"
    },
    {
      type: "exact",
      path: "/cart/shipping"
    },
    {
      type: "exact",
      path: "/cart/review"
    },
  ]
}


export {
  appConfig
}

