import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";


import {useLocation} from 'react-router-dom';
import {dismissConfirmDialog, showConfirmDialog} from "../../hooks/confirmDialog";

const Navigation = () => {

  let location = useLocation();

  let [active, setActive] = useState("")


  const closeMenu = () => {
    // let tag: any = document.getElementsByClassName("layout-overlay")[0]
    // tag.click();
    //
    // setTimeout(function (){
    //   let tag2:any=document.getElementsByClassName("layout-menu-collapsed")[0];
    //   if (tag2){
    //     tag2.classList.remove("layout-menu-collapsed")
    //   }
    // },20)
  }


  useEffect(() => {
    setActive(location.pathname)
  }, [location]);

  const showSupportDialog = () => {
    showConfirmDialog({
      title:"پشتیبانی",
      description:"برای ارتباط با پشتیبانی با شماره موبایل 09120151169" +" تماس بگیرید ",
      confirmButtonText:"بسیار خب",
      disableNegativeButton:true,
      onConfirmButtonClicked:function (){
        dismissConfirmDialog()
      }
    })
  }


  return (
    <aside
      className="layout-menu menu-vertical menu bg-menu-theme pt-4" id="layout-menu">
      <div className="app-brand demo">
        <Link to={"https://mashinno.com"} className="app-brand-link">
          {/*<span className="app-brand-text demo menu-text fw-bold">ماشین نو</span>*/}
          <img src="https://mashinno.com/uploads/settings/settings-13-color.png" alt="" width={180} style={{display: "block", margin: "auto", width: "180px"}}/>
        </Link>
        <a className="layout-menu-toggle menu-link text-large ms-auto"
          // href="javascript:void(0);"
        >
          {/*<i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"/>*/}
          {/*<i className="ti ti-x d-block d-xl-none ti-sm align-middle"/>*/}
        </a>
      </div>

      <div className="menu-inner-shadow"/>
      <ul className="menu-inner py-1 mt-3">


        <li className={`menu-item ${active == "/panel" ? "active" : ""}`}>
          <Link to={"/panel"} className="menu-link" onClick={closeMenu}>
            <i className="menu-icon tf-icons ti ti-layout-dashboard"/>
            <div data-i18n="Documentation">داشبورد</div>
          </Link>
        </li>


        {/* Dashboards */}
        <li className={`menu-item ${active == "/products" || active == "/products/mine" ? "active open" : ""}`}>
          <a className="menu-link menu-toggle"
            // href="javascript:void(0);"
          >
            <i className="menu-icon tf-icons ti ti-brand-producthunt"/>
            <div data-i18n="Dashboards">محصولات</div>
            {/*<div className="badge bg-primary rounded-pill ms-auto">5</div>*/}
          </a>
          <ul className="menu-sub open">
            <li className={`menu-item ${active == "/products" ? "active" : ""}`}>
              <Link className="menu-link" to="/products" onClick={closeMenu}>
                <div data-i18n="Analytics">همه محصوات</div>
              </Link>
            </li>

            <li className={`menu-item ${active == "/products/mine" ? "active" : ""}`}>
              <Link className="menu-link" to="/products/mine" onClick={closeMenu}>
                <div data-i18n="CRM">محصولات من</div>
              </Link>
            </li>


            <li className={`menu-item ${active == "/products/add" ? "active" : ""}`}>
              <Link className="menu-link" to="/products/add" onClick={closeMenu}>
                <div data-i18n="CRM">درخواست محصول جدید</div>
              </Link>
            </li>


          </ul>
        </li>


        <li className={`menu-item ${active == "/orders" ? "active" : ""}`}>
          <Link to={"/orders"} className="menu-link" onClick={closeMenu}>
            <i className="menu-icon tf-icons ti ti-checkup-list"/>
            <div data-i18n="Documentation">سفارشات</div>
          </Link>
        </li>

        <li className={`menu-item ${active == "/price-management" ? "active" : ""}`}>
          <Link to={"/price-management"} className="menu-link" onClick={closeMenu}>
            <i className="menu-icon tf-icons ti ti-database-dollar"/>
            <div data-i18n="Documentation">مدیریت قیمت ها</div>
          </Link>
        </li>


        {/*<li className={`menu-item ${active == "/call-for-price" ? "active" : ""}`}>*/}
        {/*  <Link to={"/call-for-price"} className="menu-link" onClick={closeMenu}>*/}
        {/*    <i className="menu-icon tf-icons ti ti-file-description"/>*/}
        {/*    <div data-i18n="Documentation">استعلام قیمت</div>*/}
        {/*  </Link>*/}
        {/*</li>*/}


        <li className={`menu-item ${active == "/accounting/history" || active == "/accounting/calc" ? "active open" : ""}`}>
          <a className="menu-link menu-toggle"
            // href="javascript:void(0);"
          >
            <i className="menu-icon tf-icons ti ti-calendar-dollar"/>
            <div data-i18n="Dashboards">مالی</div>
            {/*<div className="badge bg-primary rounded-pill ms-auto">5</div>*/}
          </a>
          <ul className="menu-sub">
            <li className={`menu-item ${active == "/accounting/history" ? "active" : ""}`}>
              <Link className="menu-link" to="/accounting/history" onClick={closeMenu}>
                <div data-i18n="Analytics">تاریخچه فروش</div>
              </Link>
            </li>

            {/*<li className={`menu-item ${active == "/accounting/calc" ? "active" : ""}`}>*/}
            {/*  <Link className="menu-link" to="/accounting/calc" onClick={closeMenu}>*/}
            {/*    <div data-i18n="CRM">محاسبه مالی</div>*/}
            {/*  </Link>*/}
            {/*</li>*/}
          </ul>
        </li>


        {/*<li className="menu-item">*/}
        {/*  <a className="menu-link menu-toggle"*/}
        {/*    // href="javascript:void(0);"*/}
        {/*  >*/}
        {/*    <i className="menu-icon tf-icons ti ti-smart-home"/>*/}
        {/*    <div data-i18n="Dashboards">تعطیلات</div>*/}
        {/*    <div className="badge bg-primary rounded-pill ms-auto">5</div>*/}
        {/*  </a>*/}
        {/*  <ul className="menu-sub">*/}
        {/*    <li className="menu-item">*/}
        {/*      <Link className="menu-link" to="/products" onClick={closeMenu}>*/}
        {/*        <div data-i18n="Analytics">تعطیلات من</div>*/}
        {/*      </Link>*/}
        {/*    </li>*/}

        {/*    <li className="menu-item active" onClick={closeMenu}>*/}
        {/*      <Link className="menu-link" to="/products/mine">*/}
        {/*        <div data-i18n="CRM">افزودن روز تعطیل</div>*/}
        {/*      </Link>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</li>*/}


        <li className="menu-item">
          <Link to={"/contract"} className="menu-link" onClick={closeMenu}>
            <i className="menu-icon tf-icons ti ti-news"/>
            <div data-i18n="Documentation">قرارداد</div>
          </Link>
        </li>

        <li className="menu-item">
          <Link to={"/tutorials"} className="menu-link" onClick={closeMenu}>
            <i className="menu-icon tf-icons ti ti-info-circle"/>
            <div data-i18n="Documentation">آموزش</div>
          </Link>
        </li>

        <li className="menu-item">
          <Link to={"/tickets"} className="menu-link" onClick={closeMenu}>
            <i className="menu-icon tf-icons ti ti-ticket"/>
            <div data-i18n="Documentation">تیکت ها</div>
          </Link>
        </li>

        <li className="menu-item" onClick={showSupportDialog} style={{cursor:"pointer"}}>
          <span className="menu-link" onClick={closeMenu}>
            <i className="menu-icon tf-icons ti ti-headphones"/>
            <div data-i18n="Documentation">پشتیبانی</div>
          </span>
        </li>


        {/*  <li className="menu-header small text-uppercase">*/}
        {/*<span className="menu-header-text" data-i18n="Apps & Pages">*/}
        {/*  برنامه‌ها و صفحات*/}
        {/*</span>*/}
        {/*  </li>*/}

      </ul>
    </aside>

  );
};

export default Navigation;