import React from 'react';
import {Route, Routes} from 'react-router-dom';
import MainLayout from "./Component/Layouts/MainLayout";
import Dashboard from "./Component/Pages/Dashboard";
import ProductPage from "./Component/Pages/ProductPage";
import OrdersPage from "./Component/Pages/OrdersPage/OrdersPage";
import ConfirmDialog from "./Component/Ui/Dialogs/ConfirmDialog";
import AuthPage from "./Component/Pages/Auth/AuthPage";
import PriceManagementPage from "./Component/Pages/PriceManagement/PriceManagementPage";
import CallForPricePage from "./Component/Pages/CallForPrice/CallForPricePage";
import ContractPage from "./Component/Pages/Contract/ContractPage";
import TutorialsPage from "./Component/Pages/Tutorials/TutorialsPage";
import NotificationsPage from "./Component/Pages/Notifications/NotificationsPage";
import TicketsPage from "./Component/Pages/Tickets/TicketsPage";
import AccountingHistory from "./Component/Pages/Accounting/History/AccountingHistory";
import LabelPage from "./Component/Pages/LabelPage/LabelPage";
import InvoicePage from "./Component/Pages/LabelPage/InvoicePage";
import HomePage from "./Component/Pages/HomePage/HomePage";
import EditProfilePage from "./Component/Pages/Profile/EditProfile/EditProfilePage";
import AddProductPage from "./Component/Pages/ProductPage/AddProduct/AddProductPage";


function App() {
  return (
    <>
      <ConfirmDialog/>
      <Routes>
        <Route element={<MainLayout/>}>
          <Route path="/panel" element={<Dashboard/>}/>
          <Route path="/products" element={<ProductPage type={"all"}/>}/>
          <Route path="/products/mine" element={<ProductPage type={"mine"}/>}/>
          <Route path="/products/add" element={<AddProductPage/>}/>
          <Route path="/orders" element={<OrdersPage/>}/>
          <Route path="/price-management" element={<PriceManagementPage/>}/>
          <Route path="/call-for-price" element={<CallForPricePage/>}/>
          <Route path="/contract" element={<ContractPage/>}/>
          <Route path="/tutorials" element={<TutorialsPage/>}/>
          <Route path="/notifications" element={<NotificationsPage/>}/>
          <Route path="/tickets" element={<TicketsPage/>}/>
          <Route path="/accounting/history" element={<AccountingHistory/>}/>
          <Route path="/edit-profile" element={<EditProfilePage/>}/>
        </Route>

        <Route path="/" element={<HomePage/>}/>

        <Route path="/label/:id" element={<LabelPage/>}/>
        <Route path="/invoice/:id" element={<InvoicePage/>}/>

        <Route path="/auth" element={<AuthPage/>}/>
        {/*<Route path="/auth/login" element={<AuthContextProvider/>}/>*/}
      </Routes>
    </>
  );
}

export default App;
