import React from 'react';
import Dialog from "../../Layouts/Dialog/Dialog";
import xToast from "../../../commons/xToast";

import "./OrderDialog.css"
import {Link} from "react-router-dom";

const OrderDetailsDialog = ({order, show, dismiss}: { dismiss: Function, order: any, show: boolean }) => {


  return (
    <Dialog show={show} height={800} setShow={dismiss} title={""} width={1000}>
      {order && <>
        <div style={{borderBottom: "1px solid #ccc", paddingRight: "8px", height: "36px", marginBottom: "16px", fontWeight: "bold", color: "#222"}}>
          جزئیات سفارش
          &nbsp;
          {order.id}
        </div>
        <table className={"table table-bordered"}>
          <thead>
          <tr>
            <th>
            <span style={{fontWeight: "normal"}}>
              وضعیت فعلی
            </span>&nbsp;:&nbsp;<span className={`status-${order.status}`}>{order.statusText}</span>
            </th>
            <th>
              <span style={{fontWeight: "normal"}}>تاریخ ثبت سفارش</span> :
              &nbsp;
              <span style={{borderBottom: "1px dashed #222", cursor: "pointer"}} onClick={() => xToast.success(order.date)}>
              {order.dateText}
            </span>
            </th>
          </tr>
          <tr>
            <th>
            <span style={{fontWeight: "normal"}}>
              زمان دریافت
            </span>&nbsp;:&nbsp;{order.receiveDate}
            </th>
            <th>
            <span style={{fontWeight: "normal"}}>
              مبلغ فاکتور
            </span>
              2,252,000
            </th>
          </tr>
          <tr>

            <th colSpan={2}>
              <span style={{fontWeight: "normal"}}>زمان پرداخت </span> :
              <div style={{direction: "ltr", textAlign: "left", display: "inline-block"}}>
                &nbsp;
                {order.date}
              </div>
            </th>
          </tr>
          <tr>
            <th colSpan={2} style={{textAlign: "center"}}>
              {/*<span className={"btn btn-primary"}>پیام ها</span>*/}
              {/*&nbsp;*/}
              <Link to={"/label/"+order.id} className={"btn btn-success"}>لیبل ارسال</Link>
              &nbsp;
              <Link to={"/invoice/"+order.id} className={"btn btn-warning"}>فاکتور</Link>
            </th>

          </tr>


          </thead>
        </table>
        <div style={{borderTop: "1px solid #ccc", paddingTop: "16px", marginTop: "16px"}} className={"table-responsive"}>
          <table className={"table table-bordered tac"}>
            <thead>
            <tr>
              <th>عکس</th>
              <th>عنوان</th>
              <th>برند</th>
              <th>سازنده</th>
              <th>تعداد</th>
              <th>قیمت</th>
              {/*<th>پیام</th>*/}
              <th>کد</th>
            </tr>
            </thead>
            <tbody>

            {order.lines.map((line: any, index: number) => {
              return <tr key={index}>
                <td>
                  <img src={"https://mashinno.com/" + line.image} alt="" width={80}/>
                </td>
                <td>
                  {line.name}
                </td>
                <td>
                  {line.brand}
                </td>
                <td>
                  {line.country}
                </td>
                <td>
                  {line.quantity}
                </td>
                <td>
                  {line.price}
                </td>
                <td>
                  {line.barcode}
                </td>
              </tr>
            })}


            </tbody>
          </table>
        </div>
      </>}
    </Dialog>
  );
};

export default OrderDetailsDialog;