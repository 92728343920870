import {numberFormat} from "../../../commons/numberHelper";
import {ProductLite} from "../../../Infrastructure/services/Types/Product";
import {dismissConfirmDialog, showConfirmDialog} from "../../../hooks/confirmDialog";
import xToast from "../../../commons/xToast";
import {webRequest} from "../../../Infrastructure/services/apiService";
import {validateAuth, validateResponse} from "../../../Infrastructure/services/validateResponse";
import useAppContext from "../../../Infrastructure/state/useAppContext";


interface Props {
  records: ProductLite[],
  // onDelete: Function,
  // onAdd: Function,
  onAddPrice: Function,
  onRefresh:Function
}


const DesktopMode = (props:Props) => {
  let {state,dispatch}=useAppContext()


  const onAddProduct = (id:any) => {
    showConfirmDialog({
      title: "افزودن محصول",
      height: 180,
      description: "محصول به لیست محصولات من اضافه شود؟ ",
      onConfirmButtonClicked: () => {
        let toast = xToast.loading("در حال افزودن محصول")
        webRequest({
          url: "/products/add",
          data: {
            ids: [id],
            mode: "add"
          },
          auth: state.auth
        }).then(response => {
          try {
            validateAuth(response,dispatch)
            validateResponse(response)
            toast.updateAndDismiss("success", "عملیات با موفقیت انجام شد", 3000)
            props.onRefresh()

          } catch (e: any) {
            toast.updateAndDismiss("error", e.message, 3000)
          }

        })

        dismissConfirmDialog()
      }
    })
  }

  const onDeleteClicked = (id:any) => {

    showConfirmDialog({
      title: "حذف محصول؟",
      height: 180,
      description: "با حذف این محصول همه قیمت های آن نیز حذف خواهند شد، ادامه می دهید؟ ",
      onConfirmButtonClicked: () => {
        let toast = xToast.loading("در حال حذف محصول")
        webRequest({
          url: "/products/add",
          data: {
            ids: [id],
            mode: "delete"
          },
          auth: state.auth
        }).then(response => {
          try {
            validateResponse(response)
            toast.updateAndDismiss("success", "عملیات با موفقیت انجام شد", 3000)
            props.onRefresh()

          } catch (e: any) {
            toast.updateAndDismiss("error", e.message, 3000)
          }

        })

        dismissConfirmDialog()
      }
    })
  }

  return (
    <>
      <div className="table-responsive">
        <table className="datatables-products table no-footer dtr-column table">
          <thead className="border-top">
          <tr style={{textAlign: "right"}}>
            {/*<th className="" style={{width: "24.8333px"}} data-col={1} aria-label="">
              <input type="checkbox" className="form-check-input"/>
            </th>*/}
            <th style={{paddingRight: "24px"}}>عکس محصول</th>
            <th> محصول</th>
            <th> کد محصول</th>
            <th> قیمت</th>
            <th> عملیات</th>
          </tr>
          </thead>
          <tbody>
          {props.records.map((row, index) => {
            let priceText = "بدون قیمت"

            if (row.priceCount === 1) {
              priceText = numberFormat(row.price) + " تومان"
            } else if (row.priceCount > 2) {
              priceText = "از " + numberFormat(row.price) + " تا " + numberFormat(row.lastPrice) + " تومان "
            }

            return <tr key={index} style={{textAlign: "right"}} className={"padding-table"}>
              <td>
                <img src={"https://mashinno.com/" + row.image} alt="" style={{width: '140px',borderRadius:"12px"}}/>
              </td>
              <td>
                <span className={"product-link"}>
                  <a href={row.link} target={"_blank"}>
                    {row.title}
                  </a>
                </span>
              </td>

              <td>{row.barcode ?? " - "}</td>
              <td>{priceText}</td>
              <td>
                {!row.isInMyProduct && <i onClick={() => onAddProduct(row.id)} className={"ti ti-square-plus fs-3"} style={{cursor: "pointer"}}></i>}
                {row.isInMyProduct && <span>
                  <i onClick={() => onDeleteClicked(row.id)} className={"ti ti-trash fs-3"} style={{cursor: "pointer"}}></i>
                </span>}
                <i onClick={() => props.onAddPrice(row)} className={"ti ti-currency-dollar fs-3"} style={{cursor: "pointer"}}></i>

              </td>
            </tr>
          })}

          {props.records.length === 0 && <tr>
            <td colSpan={5} style={{textAlign: "center"}}>موردی یافت نشد</td>
          </tr>}
          </tbody>
        </table>
        <div className="row mx-2">

          {/*  <div className="col-sm-12 col-md-6">*/}
          {/*    <div*/}
          {/*      className="dataTables_paginate paging_simple_numbers"*/}
          {/*      id="DataTables_Table_0_paginate"*/}
          {/*    >*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
};

export default DesktopMode;