import React, {useEffect, useState} from 'react';
import useAppContext from "../../../../Infrastructure/state/useAppContext";
import {webRequest} from "../../../../Infrastructure/services/apiService";
import Skeleton from "react-loading-skeleton";
import {useNavigate} from "react-router-dom";

const DonePage = () => {

  let {state, dispatch} = useAppContext()
  let [tutorials, setTutorials] = useState([])
  let [loaded, setLoaded] = useState(false)
  let navigate=useNavigate()

  useEffect(() => {
    if (!state.auth) {
      return;
    }

    webRequest({
      url: "/registration/check",
      auth: state.auth
    }).then(response => {
      try {
        let result = response.payload;

        if (result.state == "login") {
          dispatch({
            type: "on-login",
            payload: {
              auth: null,
              isVendor: false,
              isApproved: false,
              vendor: null,
              registration: {
                type: null,
                page: "login"
              }
            }
          })
          navigate("/")
        } else if (result.state == "page") {
          setTutorials(result.tutorials)
        } else if (result.state == "vendor" && result.confirmed) {

          dispatch({
            type: "on-login",
            payload: response.payload.auth
          })
          navigate("/panel")
        }


        setLoaded(true)
      } catch (e: any) {

      }
    })

  }, [state.auth]);

  return (
    <div className={"container"} style={{maxWidth: "1200px", marginTop: "48px"}}>
      <div className={"card mt-3"} style={{padding: "8px"}}>

        {loaded && <div style={{display: "flex", justifyContent: "space-between"}}>
          <div>
            <div style={{color: "#25a85a", fontWeight: "bold"}}>
              ثبت نام شما با موفقیت انجام شد
            </div>
            <div style={{color: "#666", marginTop: "1px"}}>
              نتیجه بررسی مدارک به شما اطلاع داده خواهد شد
            </div>
          </div>

          <div style={{background: "#fcfaf1", color: "#f7bf2a", width: "120px", textAlign: "center", lineHeight: "42px"}} className={"text-warning"}>
            <i className={"ti ti-clock"} style={{fontSize: '16px', paddingLeft: "4px"}}></i>
            در انتظار تایید
          </div>
        </div>}

        {(!loaded) && <div style={{display: "flex", justifyContent: "space-between"}}>
          <div>
            <div>
              <Skeleton width={200} height={10}/>
            </div>
            <div>
              <Skeleton width={280} height={10}/>
            </div>
          </div>

          <div>
            <Skeleton width={100} height={40}/>
          </div>
        </div>}

      </div>

      {/*<div style={{color: "#222", fontSize: "13px"}} className={" mt-3 mb-1"}>*/}
      {/*  شما می توانید تا زمان تایید مدارک توسط ماشین نو آموزش استفاده از پنل را مشاهده نمایید*/}
      {/*</div>*/}

      {/*<div className={"card"} style={{padding: "8px"}}>*/}
      {/*  <div className={"row row-small-padding"}>*/}
      {/*    {tutorials.map((row: any, index) => {*/}
      {/*      return <div key={index} className={"col-md-4 mb-4"}>*/}
      {/*        <video src={"https://dash.mashinno.com/" + row.videoPath} controls={true} style={{width: "100%", borderRadius: "8px"}}></video>*/}
      {/*        {row.title}*/}
      {/*      </div>*/}
      {/*    })}*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default DonePage;